.pf-header {
  margin: auto;
}

.pf-header button{
  background-color: transparent;

}

.pf-header button:hover{
  color: gray;
}

.menu{
  width: auto;
  margin: auto;
  padding: 15px;
  text-align: center;
}

.menu button{
  text-align: center;
  width: auto;
  margin: auto;
  margin-right: 20px;
  
}



.grid-path{
  width: auto;
  margin: auto;
  text-align: center;
}